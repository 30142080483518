import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  INPUT_VALUE_STEP_MESSAGE,
  INPUT_VALUE_STEP_PHOTO,
  INPUT_VALUE_STEP_WHOS_CELEBRATING,
} from '@shared/common/book/constants';
import type { IBookTokyoMetroData } from '@shared/jp/models';
import { BookPagesService } from '@studiobuki/web-core/lib/book-common';

@Injectable({
  providedIn: 'root',
})
export class BookPagesTokyoMetroService extends BookPagesService<IBookTokyoMetroData> {
  public override allSegments = [
    'segment_0',
    'segment_1',
    'segment_2',
    'segment_3',
    'segment_4',
    'segment_5',
    'segment_6',
    'segment_7',
    'segment_8',
    'segment_9',
    'segment_10',
    'segment_11',
    'segment_12',
    'segment_13',
    'segment_14',
    'segment_15',
    'segment_16',
    'segment_17',
    'segment_18',
    'segment_19',
    'segment_20',
    'segment_21',
    'segment_22',
    'segment_23',
    'segment_24',
    'segment_25',
    'segment_26',
    'segment_27',
    'segment_28',
    'segment_29',
    'segment_30',
    'segment_31',
    'segment_32',
    'segment_33',
  ];

  constructor(public override sanitizer: DomSanitizer) {
    super(sanitizer);

    this.changePageImage.subscribe((next) => {
      if (next.segmentId === '0') {
        this.segment_0 = this.getStyleUrl(next.dataURL);
        this.isChangePage.next('segment_0');
      }
      if (next.segmentId === '1') {
        this.segment_1 = this.getStyleUrl(next.dataURL);
        this.isChangePage.next(INPUT_VALUE_STEP_MESSAGE);
      }
      if (next.segmentId === '2') {
        this.segment_2 = this.getStyleUrl(next.dataURL);
        this.isChangePage.next('segment_2');
      }
      if (next.segmentId === '3') {
        this.segment_3 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '4') {
        this.segment_4 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '5') {
        this.segment_5 = this.getStyleUrl(next.dataURL);
        this.isChangePage.next(INPUT_VALUE_STEP_PHOTO);
      }
      if (next.segmentId === '6') {
        this.segment_6 = this.getStyleUrl(next.dataURL);
        this.isChangePage.next(INPUT_VALUE_STEP_PHOTO);
      }
      if (next.segmentId === '7') {
        this.segment_7 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '8') {
        this.segment_8 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '9') {
        this.segment_9 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '10') {
        this.segment_10 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '11') {
        this.segment_11 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '12') {
        this.segment_12 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '13') {
        this.segment_13 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '14') {
        this.segment_14 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '15') {
        this.segment_15 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '16') {
        this.segment_16 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '17') {
        this.segment_17 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '18') {
        this.segment_18 = this.getStyleUrl(next.dataURL);
        this.isChangePage.next(INPUT_VALUE_STEP_WHOS_CELEBRATING);
      }
      if (next.segmentId === '19') {
        this.segment_19 = this.getStyleUrl(next.dataURL);
        this.isChangePage.next(INPUT_VALUE_STEP_WHOS_CELEBRATING);
      }
      if (next.segmentId === '20') {
        this.segment_20 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '21') {
        this.segment_21 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '22') {
        this.segment_22 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '23') {
        this.segment_23 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '24') {
        this.segment_24 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '25') {
        this.segment_25 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '26') {
        this.segment_26 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '27') {
        this.segment_27 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '28') {
        this.segment_28 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '29') {
        this.segment_29 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '30') {
        this.segment_30 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '31') {
        this.segment_31 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '32') {
        this.segment_32 = this.getStyleUrl(next.dataURL);
      }
      if (next.segmentId === '33') {
        this.segment_33 = this.getStyleUrl(next.dataURL);
      }
    });
  }
}
