<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__image">
        <img
          class="image"
          src="/assets/images/about-message.jpg"
          alt="#"
        />
      </div>
      <div class="container__content">
        <div class="content">
          <div class="content__title">
            <div class="image-title">
              <img
                class="image-title__img"
                src="/assets/images/title-message.png"
                alt="#"
              />
              <div class="image-title__txt">
                東京メトロの運転士から子ども達へ
              </div>
            </div>
          </div>
          <div class="content__text">
            私は小さい頃から電車が大好きで、「運転士になって貢献したい」と入社の志望理由に書いて、東京メトロに入社したほどです。電車はかっこいい、それを運転している人って…かっこいい。そう思う気持ちは運転士になった今も変わりません。<br /><br />
            「絵本を作るんだ」と知った時、真っ先に頭に浮かんだのは電車好きな私の子どもたちのこと。私の子どもたちが喜んでくれる、そんな絵本になれば嬉しいな、と思いました。ただ、電車好きな子どもは大人もびっくりするほど電車に詳しいので、生半可な内容では満足してもらえないはずです。東京メトロの運転士として、正しい知識を共有しなくては、と意気込んで参加しました。この絵本を通して、「運転士」のかっこいいところ、大変なところ、そして何よりも、楽しいところを知って、電車や運転士が大好きだという気持ちを育んでいければうれしいです。<br /><br />
            そして、いつか運転士になったキミの電車でいろんな場所へ連れて行ってください。
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
