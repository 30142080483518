<div class="wrap">
  <div class="wrap__container">
    <p class="text">
      じめんのした、<br
        *ngIf="media.isLtMd$ | async"
      />とっても　ふかいところを、<br
        *ngIf="media.isLtXl$ | async"
      />たくさんの　おきゃくさんを<br
        *ngIf="media.isLtMd$ | async"
      />のせて　すすむんだ。
    </p>
  </div>
</div>
