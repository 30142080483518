<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <p class="container__description">
        この絵本は東京メトロと、物語の主人公になれるパーソナライズ絵本を手掛けるBÜKIの協働で作られました
      </p>
      <div class="container__content">
        <div class="card">
          <div class="card__title">東京地下鉄株式会社</div>
          <div class="card__content">
            <p class="card-text">東京都台東区東上野三丁目19番6号</p>
            <p class="card-text">2004年4月創業</p>
            <p class="card-text">
              企業サイトは<a
                href="https://www.tokyometro.jp/index.html"
                target="_blank"
                >こちら</a
              >
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card__title">STUDIO BUKI株式会社</div>
          <div class="card__content">
            <p class="card-text">東京都世田谷区駒沢公園1-1 Tote駒沢公園</p>
            <p class="card-text">2019年5月創業</p>
            <p class="card-text">
              企業サイトは<a
                href="https://hellobuki.com/jp"
                target="_blank"
                >こちら</a
              >
            </p>
            <a
              href="mailto:yay@hellobuki.com"
              class="card-text"
              >yay@hellobuki.com</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
