<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title type="books">贈る絵本を選ぶ</lib-image-title>
      </div>
      <div class="container__content">
        <div class="content">
          <div class="content__compensator"></div>
          <ng-container *ngFor="let book of books">
            <div
              *ngIf="!(alias$ | async) || (alias$ | async) === book.alias"
              @slideY
              class="content__book"
            >
              <div class="book-wrap">
                <div class="book-wrap__item">
                  <app-book
                    [selected]="(alias$ | async) === book.alias"
                    [alias]="book.alias"
                    [photo]="book.menuPhoto"
                    [caption]="book.caption"
                    [summary]="book.summary"
                    [notice]="book.notice"
                    [prices]="book.prices"
                    [covers]="book.covers"
                    [shippingIncluded]="shipping"
                    (bookClick)="onBookClick(book.alias)"
                    [cover]="cover$ | async"
                    (coverChange)="cover = $event"
                    [wrapping]="wrapping$ | async"
                    (wrappingChange)="wrapping = $event"
                  ></app-book>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="alias$ | async"
            class="content__change"
            @slideY
          >
            <div class="change-wrap">
              <div class="change-wrap__item">
                <app-change (buttonClick)="onChangeClick()"></app-change>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
