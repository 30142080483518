<div class="container">
  <div class="container__section container__section_navigation">
    <nav
      class="navigation"
      (mouseenter)="onMouseenter()"
      (mouseleave)="onMouseleave()"
    >
      <app-topbar class="navigation-topbar"></app-topbar>
      <div
        class="navigation-book-button"
        *ngIf="!environment.production"
      >
        <app-book-button [booksList]="userService.booksList"></app-book-button>
      </div>

      <div
        #wrap
        class="navigation-bar"
      >
        <div class="navigation-bar__items navigation-bar__items_start">
          <button
            fxHide.gt-lg
            class="navigation-button"
            (click)="menuToggle()"
          >
            <div class="navigation-button__icon">
              <div
                class="icon-menu"
                [ngClass]="{
                  active: isMenuActive
                }"
              >
                <div class="sw-topper"></div>
                <div class="sw-bottom"></div>
                <div class="sw-footer"></div>
              </div>
            </div>
          </button>
          <app-navigation-item
            fxHide.lt-xl
            title="絵本を作る"
            [link]="ROUTE_PATH_INDEX + '#preform'"
          ></app-navigation-item>
          <app-navigation-item
            fxHide.lt-xl
            title="ギフトコード"
            [link]="ROUTE_PATH_GIFT_BUY"
          ></app-navigation-item>
          <app-navigation-item
            fxHide.lt-xl
            title="レビュー"
            [link]="ROUTE_PATH_REVIEWS"
          ></app-navigation-item>
        </div>
        <div class="navigation-bar__logo">
          <a
            class="navigation-logo"
            [routerLink]="ROUTE_PATH_INDEX"
          >
            <app-logo></app-logo>
          </a>
        </div>
        <div class="navigation-bar__items navigation-bar__items_end">
          <app-navigation-item
            fxHide.lt-xl
            title="このサイトについて"
            [link]="ROUTE_PATH_ABOUT"
          ></app-navigation-item>
          <app-navigation-item
            fxHide.lt-xl
            title="お問い合わせ"
            [link]="ROUTE_PATH_CONTACTS"
          ></app-navigation-item>
          <app-navigation-item
            *ngIf="headerService.isCartVisible$ | async"
            [title]="getIconItemText('カート')"
            [link]="ROUTE_PATH_CART"
            svgIconSrc="assets/images/icons/cart.svg"
            [label]="userService.booksInCartList.length"
          ></app-navigation-item>
        </div>
      </div>
    </nav>
  </div>
  <div class="container__section container__section_menu">
    <div
      class="backdrop"
      [class.backdrop_active]="isMenuActive"
      (click)="onBackdropClick()"
      (touchstart)="onBackdropClick()"
    ></div>
    <div
      class="menu"
      [class.menu_active]="isMenuActive"
      fxHide.gt-lg
      (mouseenter)="onMouseenter()"
      (mouseleave)="onMouseleave()"
    >
      <!-- <app-menu-item
        class="menu__item"
        title="絵本"
        [children]="mobileBooksChildren"
      ></app-menu-item> -->
      <!-- <br class="menu__item" /> -->
      <app-menu-item
        class="menu__item"
        title="絵本をつくる"
        [link]="ROUTE_PATH_INDEX + '#preform'"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="ギフトコード"
        [link]="ROUTE_PATH_GIFT_BUY"
      ></app-menu-item>
      <!-- <br class="menu__item" /> -->
      <app-menu-item
        class="menu__item"
        title="レビュー"
        [link]="ROUTE_PATH_REVIEWS"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="このサイトについて"
        [link]="ROUTE_PATH_ABOUT"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="お問い合わせ"
        [link]="ROUTE_PATH_CONTACTS"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="FAQ"
        [link]="ROUTE_PATH_FAQ"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="特定商取引法に基づく表示"
        [link]="ROUTE_PATH_ORDERCONTRACT"
        [small]="true"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="資金決済法に基づく表示"
        [link]="ROUTE_PATH_ORDERCONTRACT_GIFT"
        [small]="true"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="利用規約"
        [link]="ROUTE_PATH_TERMS"
        [small]="true"
      ></app-menu-item>
      <app-menu-item
        class="menu__item"
        title="プライバシーポリシー"
        [link]="ROUTE_PATH_POLICY"
        [small]="true"
      ></app-menu-item>
      <!-- <app-menu-item
        class="menu__item"
        title="レビュー"
        [link]="ROUTE_PATH_REVIEWS"
      ></app-menu-item> -->
      <!-- <app-menu-item
        class="menu__item"
        title="お問い合わせ"
        [link]="ROUTE_PATH_CONTACTS"
      ></app-menu-item> -->
      <!-- <br class="menu__item" /> -->
      <!-- <br class="menu__item" /> -->
      <!-- <app-menu-item
        class="menu__item"
        title="FAQ"
        [link]="ROUTE_PATH_FAQ"
      ></app-menu-item> -->
      <!-- <app-menu-item
        class="menu__item"
        title="BÜKIについて"
        [link]="ROUTE_PATH_ABOUT"
      ></app-menu-item> -->
      <!-- <app-menu-item
        class="menu__item"
        title="法人向けサービス"
        [link]="ROUTE_PATH_COLLABORATION"
      ></app-menu-item> -->
    </div>
  </div>
</div>

<!-- <mat-menu #menu="matMenu">
  <button mat-menu-item>About BÜKI</button>
  <button mat-menu-item routerLink="/faq">FAQ</button>
  <button mat-menu-item>Contact Us</button>

  <button mat-menu-item routerLink="/404">404</button>

  <button mat-menu-item *ngFor="let bookItem of userService.booksList" routerLink="/book/{{bookItem.bookId}}">
    {{bookItem.heroName}} {{bookItem.bookId}}
  </button>
</mat-menu> -->
