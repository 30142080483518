<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <h2 class="container__title">
        目指す場所へ、大切な夢へ、<br
          *ngIf="media.isLtMd$ | async"
        />ワクワクする未来へ
      </h2>
      <p class="container__text">
        電車が前を向いて進んでいくように、<br
          *ngIf="media.isLtXl$ | async"
        />これからを生きる子どもたちが、<br />
        目的地に向かって進んで行くことが<br
          *ngIf="media.isLtSm$ | async"
        />できますようにと、<br *ngIf="media.isLtXl$ | async" />願いを込めて。
      </p>
    </div>
  </div>
</div>
