<div class="container">
  <div class="container__background"></div>
  <div class="container__content">
    <div class="content">
      <div class="content__text">
        <p class="text">
          オーダーメイドの電車絵本を<br />もっと手軽にプレゼント
        </p>
      </div>
      <div class="content__button">
        <lib-button
          color="accent"
          size="sm"
          (buttonClick)="onButtonClick()"
          ><b>ギフトコードで贈る</b></lib-button
        >
      </div>
    </div>
  </div>
</div>
