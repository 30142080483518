import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerOneComponent } from './banner-one.component';
import { InfoComponent } from './components/info/info.component';
import { TimeComponent } from './components/time/time.component';
import { BackgroundComponent } from './components/background/background.component';

@NgModule({
  declarations: [
    BannerOneComponent,
    InfoComponent,
    TimeComponent,
    BackgroundComponent,
  ],
  imports: [CommonModule],
  exports: [BannerOneComponent],
})
export class BannerOneModule {}
