<section class="wrap">
  <div class="wrap__character"></div>
  <div class="wrap__container">
    <div class="container">
      <h1 class="container__title">お買い上げありがとうございました！</h1>
      <p
        *ngIf="!media.isActive('lt-md')"
        class="container__text"
      >
        ご注文ありがとうございます。主人公となる子に読んでもらえるのが楽しみです。
      </p>
      <div
        *ngIf="barOrderId"
        class="container__plate"
      >
        <div class="plate">
          <p class="plate__label">ご注文番号</p>
          <p class="plate__item">{{ barOrderId }}</p>
        </div>
      </div>
      <p
        *ngIf="barOrderId"
        class="container__text"
      >
        メモしておくかスクリーンショットをとっておくのをおすすめします
      </p>
      <app-block-sns-card class="container__sns"></app-block-sns-card>
      <div class="container__button">
        <lib-button
          class="button"
          type="button"
          [fullWidth]="true"
          (buttonClick)="router.navigate(['/'])"
          >Home</lib-button
        >
      </div>
    </div>
  </div>
</section>
