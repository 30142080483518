import type { OnDestroy, OnInit } from '@angular/core';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import capitalize from 'lodash/capitalize';
import { Logger } from '@shared/common/logger';
import { getRegion, getRegionByNameEnglish } from '@shared/jp/shipping/utils';
import type {
  IBookInOrder,
  IAddress,
  IAddressShippingData,
} from '@shared/jp/interfaces';
import { getFullName } from '@shared/common/utils';
import type {
  TRegion,
  TRegionNameEnglish,
  TShippingMethod,
} from '@shared/jp/shipping/interfaces';
import type { IShipping } from '@shared/jp/data/shipping/types';
import { FormEmailComponent } from '@studiobuki/web-core/lib/form-email';
import { FormStripeAddressComponent } from '@studiobuki/web-core/lib/form-stripe-address';
import { FormPaymentComponent } from '@studiobuki/web-core/lib/form-payment';
import type { IDiscountCampaign } from '@shared/jp/discount/interfaces';
import Subscriber from '@shared/common/subscriber';
import { REGION_NAME_ENGLISH_DEFAULT } from '@shared/jp/shipping/constants';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { getTotal } from '@studiobuki/web-core/lib/price';
import type { IFormData, IStep } from '../../interfaces';

const log = new Logger('MainComponent');

enum ESummary {
  email = 'メールアドレス',
  shippingAddress = 'お届け先情報',
  billingAddress = '請求先情報',
  shippingMethod = '配送方法',
}

const getSummaryAddress = (form: IAddress) => {
  const region = getRegionByNameEnglish(form.state);

  return `
    〒${form.postalCode}<br>
    ${region.name} ${form.line1} ${form.line2}<br>
    ${getFullName(form.lastName, form.firstName)}さま<br>
    ${form.phone}
  `;
};

@Component({
  selector: 'app-main[shippings][disabledShippings]',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @Input() step!: IStep['id'];

  @Output() stepChange = new EventEmitter<IStep['id']>();

  @Input() books!: IBookInOrder[];

  @Output() dataChange = new EventEmitter<IFormData>();

  @Input() disabledShippings!: TShippingMethod[];

  @Input() shippings!: IShipping[];

  @Input() set shipping(shipping: IShipping | undefined) {
    this.formShippingMethod = shipping?.id;
  }

  get shipping() {
    return this.shippings.find(({ id }) => id === this.formShippingMethod);
  }

  @Input() isBackDisabled = false;

  @Input() isNextDisabled = false;

  @Output() backClick = new EventEmitter<void>();

  @Output() nextClick = new EventEmitter<void>();

  @ViewChild('formEmailRef')
  public formEmailComponent?: FormEmailComponent;

  @ViewChild('formBillingAddressRef')
  public formBillingAddressComponent?: FormStripeAddressComponent<
    TRegionNameEnglish,
    'JP'
  >;

  @ViewChild('formShippingAddressRef')
  public formShippingAddressComponent?: FormStripeAddressComponent<
    TRegionNameEnglish,
    'JP'
  >;

  @ViewChild(FormPaymentComponent)
  public formPaymentComponent?: FormPaymentComponent;

  public formEmail: IFormData['formEmail'];

  public formBillingAddress: IFormData['formBillingAddress'];

  public formShippingAddress: IFormData['formShippingAddress'];

  public formSameBillingAddress: IFormData['formSameBillingAddress'] = true;

  public formShippingMethod: IFormData['formShippingMethod'];

  public formPayment: IFormData['formPayment'];

  public activeDiscountCampaign?: IDiscountCampaign | undefined;

  public readonly ESummary = ESummary;

  public readonly environment = environment;

  public readonly parseStateFn = (name: any) => getRegion(name).nameEnglish;

  private readonly _sub = new Subscriber();

  public readonly regionNameEnglishDefault: TRegionNameEnglish =
    REGION_NAME_ENGLISH_DEFAULT;

  public readonly addressDefault: Omit<IAddressShippingData, 'email'> = {
    firstName: 'John',
    lastName: 'Doe',
    line1: 'Kamimuneoka Shiki',
    line2: 'Kamimuneoka District',
    city: 'Tokyo',
    postalCode: '353-0001',
    country: 'JP',
    state: 'Tokyo',
    phone: '090-0000-0001',
    // email: 'test@mail.com',
  };

  public readonly addressAltDefault: Omit<IAddressShippingData, 'email'> = {
    firstName: 'Jane',
    lastName: 'Doe',
    line1: 'Nakamuneoka Shiki',
    line2: 'Nakamuneoka District',
    city: 'Tokyo',
    postalCode: '353-0002',
    country: 'JP',
    state: 'Tokyo',
    phone: '090-0000-0002',
    // email: 'test@mail.com',
  };

  // * summary getters
  get summaryEmail(): string {
    return this.formEmail?.email || '';
  }

  get summaryShippingAddress() {
    const form = this.formShippingAddress;

    return form ? getSummaryAddress(form) : '';
  }

  get summaryBillingAddress() {
    const form = this.formSameBillingAddress
      ? this.formShippingAddress
      : this.formBillingAddress;

    return form ? getSummaryAddress(form) : '';
  }

  get summaryShippingMethod(): string {
    const { shipping } = this;
    let str = '';

    if (shipping) {
      const { name, price, currency } = shipping;

      str = `${capitalize(name)} ・ ${getTotal(currency, price)} (税込)`;
    }

    return str;
  }

  get summaryShow(): boolean {
    return (
      !!(
        this.summaryEmail ||
        this.summaryBillingAddress ||
        this.summaryShippingAddress ||
        this.summaryShippingMethod
      ) && this.step !== 1
    );
  }

  get region(): TRegion {
    const form = this.formShippingAddress;

    if (form) {
      return getRegionByNameEnglish(form.state);
    }

    return getRegionByNameEnglish(REGION_NAME_ENGLISH_DEFAULT);
  }

  constructor(private _discountService: DiscountService) {}

  ngOnInit() {
    this._sub.push(
      this._discountService.activeDiscountCampaign$.subscribe(
        (discountCampaign) => {
          this.activeDiscountCampaign = discountCampaign;
        },
      ),
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  public onChange() {
    const data: IFormData = {
      formEmail: this.formEmail,
      formBillingAddress: this.formBillingAddress,
      formShippingAddress: this.formShippingAddress,
      formSameBillingAddress: this.formSameBillingAddress,
      formShippingMethod: this.formShippingMethod,
      formPayment: this.formPayment,
    };

    this.dataChange.emit(data);
  }

  public summaryOnChange(key: ESummary): void {
    log.info('change', key);

    switch (key) {
      case ESummary.shippingMethod:
        this.stepChange.emit(2);
        break;
      default:
        this.stepChange.emit(1);
        break;
    }
  }

  /**
   * filling the customer info form group with test data
   */
  // public fillCustomerInfo(
  //   data: PartialUndefined<IFormData['formBillingAddress']> = {},
  // ): void {
  //   this.formBillingAddressComponent.formGroup.setValue(
  //     Object.assign(
  //       this.formBillingAddressComponent.formGroup.value || {},
  //       data,
  //     ),
  //   );
  // }
}
