import { Component } from '@angular/core';
import { MediaService } from '@studiobuki/web-core/lib/media';

@Component({
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent {
  constructor(public media: MediaService) {}
}
