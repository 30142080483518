<button
  *ngIf="visible$ | async"
  class="container"
  (click)="onClick()"
  @fade
>
  <img
    class="container__icon"
    src="assets/images/top.png"
  />
</button>
