import { Injectable } from '@angular/core';
import {
  INPUT_VALUE_STEP_CHARACTER,
  INPUT_VALUE_STEP_MESSAGE,
  INPUT_VALUE_STEP_PHOTO,
  INPUT_VALUE_STEP_TRAIN_LINE,
} from '@shared/common/book/constants';
import { PHOTO_TOKYO_METRO } from '@shared/jp/book/constants';
import type { IBookTokyoMetroData } from '@shared/jp/models';
import { BookService } from '@studiobuki/web-core/lib/book-common';
import type { TCreateUserBookOmitKeys } from '@studiobuki/web-core/lib/firebase';
import { FirebaseService } from 'src/app/services/firebase.service';
import type { TBookMode } from '@studiobuki/web-core/lib/routing';
import { ROUTE_PATH_QUERY_PARAM_MODE_USER } from '@studiobuki/web-core/lib/routing';
import TokyoMetro from 'src/app/data/books/TokyoMetro';
import { getKidstitle } from '@shared/jp/book/utils';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import { Logger } from '@shared/common/logger';
import { BookPagesTokyoMetroService } from './book-pages-tokyo-metro.service';

type TBookTokyoMetroCreateData = Pick<
  IBookTokyoMetroData,
  'heroName' | 'kunChan' | 'trainLine' | 'gender' | 'character' | 'glasses'
>;

const log = new Logger('BookTokyoMetroService');

@Injectable({
  providedIn: 'root',
})
export class BookTokyoMetroService extends BookService<
  IBookTokyoMetroData,
  TBookTokyoMetroCreateData
> {
  constructor(
    protected override _firebaseService: FirebaseService,
    protected override _bookPagesService: BookPagesTokyoMetroService,
    private _loaderService: LoaderService,
  ) {
    super(_firebaseService, _bookPagesService);
  }

  /** a method to create initial book data */
  // eslint-disable-next-line class-methods-use-this
  protected override _createBookData<O extends Partial<IBookTokyoMetroData>>(
    /** data to create the book from */
    createBookData: TBookTokyoMetroCreateData,
    /** book data to owerride the defaults */
    overrideBookData: O,
  ) {
    const { kunChan } = createBookData;
    const kidstitle = getKidstitle(kunChan);
    const newBook: Omit<IBookTokyoMetroData, TCreateUserBookOmitKeys> = {
      ...createBookData,
      steps: {
        [INPUT_VALUE_STEP_CHARACTER]: {
          stepType: INPUT_VALUE_STEP_CHARACTER,
          isDone: true,
        },
        [INPUT_VALUE_STEP_TRAIN_LINE]: {
          stepType: INPUT_VALUE_STEP_TRAIN_LINE,
          isDone: true,
        },
        [INPUT_VALUE_STEP_MESSAGE]: {
          stepType: INPUT_VALUE_STEP_MESSAGE,
          isDone: false,
        },
        [INPUT_VALUE_STEP_PHOTO]: {
          stepType: INPUT_VALUE_STEP_PHOTO,
          isDone: false,
        },
      },
      // uid: undefined,
      // bookId: undefined,
      // fileUploadStatus: 0,
      multiFiles: {
        [PHOTO_TOKYO_METRO]: {
          fileUploadStatus: 0,
        },
      },
      // createTimeStamp: _serverTimestamp,
      alias: TokyoMetro.alias,
      cover: TokyoMetro.cover,
      messageText:
        `${createBookData.heroName}${kidstitle}へ\n\n` +
        `でんしゃが　まえを　てらして\n` +
        `ぐんぐんと　すすんでいくように、\n` +
        `これからも　${createBookData.heroName}${kidstitle}が\n` +
        `じぶんの　ちからで　ゆめに　むかって\n` +
        `すすんでいくことが　できますように。\n`,
    };

    return { ...newBook, ...overrideBookData };
  }

  override async loadBook(
    bookId: string,
    onBookDataLoad:
      | ((bookData: IBookTokyoMetroData) => Promise<void>)
      | ((bookData: IBookTokyoMetroData) => void),
    mode: TBookMode = ROUTE_PATH_QUERY_PARAM_MODE_USER,
  ) {
    const bookData = await super.loadBook(bookId, onBookDataLoad, mode);

    this._bookPagesService.newBookInit(bookData);

    const multiFilesEntries = Object.entries(bookData.multiFiles);
    this._loaderService.pushProgressMax(multiFilesEntries.length * 2);

    await Promise.all(
      multiFilesEntries.map(async ([photoName, data]) => {
        if (data.fileUploadStatus === 10) {
          log.info('loading', photoName);
          await this._multiFileLoad(photoName);
          log.info('loaded', photoName);
        }

        this._loaderService.pushProgressValue(2);
      }),
    );

    this.textChangeSubscribe.next();
    this.messageTextChangeSubscribe.next();

    return bookData;
  }
}
