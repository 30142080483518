import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import {
  ROUTE_404,
  ROUTE_ABOUT,
  ROUTE_AUTH,
  ROUTE_AUTH_WITH_TYPE,
  ROUTE_BOOK,
  ROUTE_BOOK_TOKYO_METRO,
  ROUTE_CART,
  ROUTE_CONTACTS,
  ROUTE_COVER,
  ROUTE_FAQ,
  ROUTE_GIFT_BUY,
  ROUTE_GIFT_BUY_SPECIAL,
  ROUTE_GIFT_ORDER,
  ROUTE_GIFT_ORDER_WITH_ORDER_ID,
  ROUTE_GIFT_THANKS,
  ROUTE_GIFT_USE,
  ROUTE_GIFT_USE_NO_LOGO,
  ROUTE_GIFT_USE_NO_LOGO_WITH_GIFT_ID,
  ROUTE_GIFT_USE_WITH_GIFT_ID,
  ROUTE_HOME,
  ROUTE_ORDER,
  ROUTE_ORDERCONTRACT,
  ROUTE_ORDERCONTRACT_GIFT,
  ROUTE_POLICY,
  ROUTE_REVIEWS,
  ROUTE_TERMS,
} from './routes';

const routes: Routes = [
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_TERMS,
  ROUTE_ORDERCONTRACT,
  ROUTE_ORDERCONTRACT_GIFT,
  ROUTE_POLICY,
  ROUTE_FAQ,
  ROUTE_CONTACTS,
  ROUTE_CART,
  ROUTE_ORDER,
  ROUTE_BOOK,
  ROUTE_BOOK_TOKYO_METRO,
  ROUTE_COVER,
  ROUTE_GIFT_BUY,
  ROUTE_GIFT_BUY_SPECIAL,
  ROUTE_GIFT_USE,
  ROUTE_GIFT_USE_WITH_GIFT_ID,
  ROUTE_GIFT_USE_NO_LOGO,
  ROUTE_GIFT_USE_NO_LOGO_WITH_GIFT_ID,
  ROUTE_GIFT_THANKS,
  ROUTE_GIFT_ORDER,
  ROUTE_GIFT_ORDER_WITH_ORDER_ID,
  ROUTE_REVIEWS,
  ROUTE_404,
  ROUTE_AUTH,
  ROUTE_AUTH_WITH_TYPE,
  { path: '**', redirectTo: ROUTE_404.path },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
