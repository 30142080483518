import {
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  INPUT_NAME_CLOTH_COLOR,
  INPUT_NAME_COVER_IMAGE,
  INPUT_NAME_FREKLES,
  INPUT_NAME_HAIR_COLOR,
  INPUT_NAME_HAIR_STYLE,
  INPUT_NAME_KIDS_BIRTHDAY,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_LANGUAGE,
  INPUT_NAME_P1_HAIR_COLOR,
  INPUT_NAME_P1_SKIN_COLOR,
  INPUT_NAME_P2_HAIR_COLOR,
  INPUT_NAME_P2_SKIN_COLOR,
  INPUT_NAME_PARENTS,
  INPUT_NAME_SEASON,
  INPUT_NAME_SKIN_COLOR,
  INPUT_VALUE_CLOTH_COLOR_BLUE,
  INPUT_VALUE_CLOTH_COLOR_GREEN,
  INPUT_VALUE_CLOTH_COLOR_RED,
  INPUT_VALUE_CLOTH_COLOR_YELLOW,
  INPUT_VALUE_COVER_TYPE_HARD,
  INPUT_VALUE_COVER_TYPE_SOFT,
  INPUT_VALUE_ENGLISH,
  INPUT_VALUE_HAIR_COLOR_BLACK,
  INPUT_VALUE_HAIR_COLOR_BLONDE,
  INPUT_VALUE_HAIR_COLOR_BROWN,
  INPUT_VALUE_HAIR_COLOR_RED,
  INPUT_VALUE_HAIR_STYLE_CURL,
  INPUT_VALUE_HAIR_STYLE_STRAIGHT,
  INPUT_VALUE_JAPANESE,
  INPUT_VALUE_PARENTS_DAD,
  INPUT_VALUE_PARENTS_DAD_AND_DAD,
  INPUT_VALUE_PARENTS_MOM,
  INPUT_VALUE_PARENTS_MOM_AND_DAD,
  INPUT_VALUE_PARENTS_MOM_AND_MOM,
  INPUT_VALUE_SKIN_COLOR_BLACK,
  INPUT_VALUE_SKIN_COLOR_BROWN,
  INPUT_VALUE_SKIN_COLOR_NUTMEG,
  INPUT_VALUE_SKIN_COLOR_WHITE,
  INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
  INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
  INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
  INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
} from '@shared/common/book/constants';
import {
  BOOK_PRICE_WELCOME_TO_THE_WOLD,
  BOOK_PRICE_WELCOME_TO_THE_WOLD_HARD_COVER,
} from '@shared/jp/book/constants';
import { getBookNameEnValidatorList } from '@shared/common/data/books/inputs/validators';
import { getBookNameValidatorList } from '@shared/jp/data/books/inputs/validators';
import {
  GET_BOOK_NAME_ERROR,
  GET_BOOK_NAME_ERROR_EN,
} from '@shared/jp/data/books/inputs/validators.constants';
import type {
  TClothColor,
  THairColor,
  THairStyle,
  TParents,
  TSkinColor,
} from '@shared/common/book/interfaces';
import type { TLanguage } from '@shared/jp/book/interfaces';
import { languageArr } from '@shared/jp/book/data';
import type { IBook } from '@shared/jp/data/books/types';
import { getBookTextInputData } from '@shared/common/data/books/inputs/text/utils';
import {
  getBookRadioInputData,
  getBookRadioInputOptionData,
} from '@shared/common/data/books/inputs/radio/utils';
import { getBookPriceData } from '@shared/jp/data/books/prices/utils';
import {
  getBookSelectInputData,
  getBookSelectInputOptionData,
} from '@shared/common/data/books/inputs/select/utils';
import { EBookBannerTextColor } from '@shared/common/data/books/banner/types';
import { EBookDescriptionTextItemType } from '@shared/common/data/books/desicription/types';
import { getBookDateInputData } from '@shared/common/data/books/inputs/date/utils';
// import StaryDream from './StaryDream';
// import { WhosBirthdayTomorrowInputsSeason } from './WhosBirthdayTomorrow';
import { EBookSelectInputType } from '@shared/common/data/books/inputs/select/types';
import {
  getBookRadioColorInputData,
  getBookRadioColorInputOptionData,
} from '@shared/common/data/books/inputs/radioColor/utils';
import { getBookCheckboxInputData } from '@shared/common/data/books/inputs/checkbox/utils';
import {
  INPUT_NAME_RESERVED_CANVAS1,
  INPUT_NAME_RESERVED_CANVAS2,
} from '@shared/common/data/books/inputs/canvas/constants';
import type { IBookCanvasInputItem } from '@shared/common/data/books/inputs/canvas/types';
import {
  bookInputShowWhen,
  bookInputValidatorWhen,
  bookInputsToNameValueMap,
} from '@shared/common/data/books/inputs/utils';
import { getBookCanvasInputData } from '@shared/common/data/books/inputs/canvas/utils';
import {
  getBookRadioCoverInputData,
  getBookRadioCoverInputOptionData,
} from '@shared/common/data/books/inputs/radioCover/utils';
import {
  PURPOSE_ADVENTURE,
  PURPOSE_BIRTH_GIFT,
} from '@shared/common/data/books/properties/constants';
import { getCoverHard, getCoverSoft } from './_utils';

/**
 * * validatorWhen and showWhen are set inside IBook['inputs']
 * * by mutation funtions `bookInputValidatorWhen` and `bookInputShowWhenCheck`
 * * to make it possible to assign inputs between each other
 */

const WelcomeToTheWorldInputsKidsNameValidator = getBookNameValidatorList(8);
const WelcomeToTheWorldInputsKidsNameValidatorEn =
  getBookNameEnValidatorList(12);

export const WelcomeToTheWorldInputsKidsName = getBookTextInputData({
  label: 'Kid’s name',
  // placeholder: 'ひらがな８文字まで',
  get placeholder() {
    // @ts-ignore
    return this.validator === WelcomeToTheWorldInputsKidsNameValidatorEn
      ? ('Up to 12 characters EN' as const)
      : ('Up to 8 characters' as const);
  },
  // value: '',
  name: INPUT_NAME_KIDS_NAME,
  // will be added in inputs array
  // validator: getBookNameValidatorList(8),
  get error() {
    // @ts-ignore
    return this.validator === WelcomeToTheWorldInputsKidsNameValidatorEn
      ? GET_BOOK_NAME_ERROR_EN(12)
      : GET_BOOK_NAME_ERROR(8);
  },
} as const);

export const WelcomeToTheWorldInputsKidsBirthday = getBookDateInputData({
  label: 'Kid’s birthday',
  placeholder: 'yyyy/mm/dd',
  // value: new Date(),
  max: new Date(),
  name: INPUT_NAME_KIDS_BIRTHDAY,
} as const);

export const WelcomeToTheWorldInputsParents = getBookSelectInputData({
  selectType: EBookSelectInputType.imageText,
  label: 'Parents',
  placeholder: 'Please choose',
  options: [
    getBookSelectInputOptionData({
      label: 'Mom and Dad',
      imgSrc: 'assets/images/parents/mom-dad.png',
      value: INPUT_VALUE_PARENTS_MOM_AND_DAD,
    }),
    getBookSelectInputOptionData({
      label: 'Mom and Mom',
      imgSrc: 'assets/images/parents/mom-mom.png',
      value: INPUT_VALUE_PARENTS_MOM_AND_MOM,
    }),
    getBookSelectInputOptionData({
      label: 'Dad and Dad',
      imgSrc: 'assets/images/parents/dad-dad.png',
      value: INPUT_VALUE_PARENTS_DAD_AND_DAD,
    }),
    getBookSelectInputOptionData({
      label: 'Mom',
      imgSrc: 'assets/images/parents/mom.png',
      value: INPUT_VALUE_PARENTS_MOM,
    }),
    getBookSelectInputOptionData({
      label: 'Dad',
      imgSrc: 'assets/images/parents/dad.png',
      value: INPUT_VALUE_PARENTS_DAD,
    }),
  ],
  // value: '',
  name: INPUT_NAME_PARENTS,
});

const WelcomeToTheWorldInputsSkinColorOptions = [
  getBookRadioColorInputOptionData({
    color: '#FFEAD6',
    value: INPUT_VALUE_SKIN_COLOR_WHITE,
  }),
  getBookRadioColorInputOptionData({
    color: '#FAD5A2',
    value: INPUT_VALUE_SKIN_COLOR_NUTMEG,
  }),
  getBookRadioColorInputOptionData({
    color: '#CF9970',
    value: INPUT_VALUE_SKIN_COLOR_BROWN,
  }),
  getBookRadioColorInputOptionData({
    color: '#B28262',
    value: INPUT_VALUE_SKIN_COLOR_BLACK,
  }),
];

export const WelcomeToTheWorldInputsSkinColor = getBookRadioColorInputData({
  label: 'Skin color',
  options: WelcomeToTheWorldInputsSkinColorOptions,
  value: INPUT_VALUE_SKIN_COLOR_WHITE,
  name: INPUT_NAME_SKIN_COLOR,
});

export const WelcomeToTheWorldInputsFrekles = getBookCheckboxInputData({
  label: 'Frekles',
  name: INPUT_NAME_FREKLES,
  value: false,
} as const);

const WelcomeToTheWorldInputsHairColorOptions = [
  getBookRadioColorInputOptionData({
    color: '#F8D992',
    value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  }),
  getBookRadioColorInputOptionData({
    color: '#CF744B',
    value: INPUT_VALUE_HAIR_COLOR_RED,
  }),
  getBookRadioColorInputOptionData({
    color: '#9F6D48',
    value: INPUT_VALUE_HAIR_COLOR_BROWN,
  }),
  getBookRadioColorInputOptionData({
    color: '#4D423B',
    value: INPUT_VALUE_HAIR_COLOR_BLACK,
  }),
];

export const WelcomeToTheWorldInputsHairColor = getBookRadioColorInputData({
  label: 'Hair color',
  options: WelcomeToTheWorldInputsHairColorOptions,
  value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  name: INPUT_NAME_HAIR_COLOR,
});

export const WelcomeToTheWorldInputsHairStyle = getBookRadioInputData({
  label: 'Hair style',
  options: [
    getBookRadioInputOptionData({
      label: 'Straight',
      imgSrc: 'assets/images/wttw-canvas/form-icons/straight.png',
      value: INPUT_VALUE_HAIR_STYLE_STRAIGHT,
    }),
    getBookRadioInputOptionData({
      label: 'Curl',
      imgSrc: 'assets/images/wttw-canvas/form-icons/curl.png',
      value: INPUT_VALUE_HAIR_STYLE_CURL,
    }),
  ],
  value: INPUT_VALUE_HAIR_STYLE_STRAIGHT,
  name: INPUT_NAME_HAIR_STYLE,
});

export const WelcomeToTheWorldInputsClothColor = getBookRadioColorInputData({
  label: 'Cloth color',
  options: [
    getBookRadioColorInputOptionData({
      color: '#7ED0DA',
      value: INPUT_VALUE_CLOTH_COLOR_BLUE,
    }),
    getBookRadioColorInputOptionData({
      color: '#FBD269',
      value: INPUT_VALUE_CLOTH_COLOR_YELLOW,
    }),
    getBookRadioColorInputOptionData({
      color: '#F3A484',
      value: INPUT_VALUE_CLOTH_COLOR_RED,
    }),
    getBookRadioColorInputOptionData({
      color: '#C3CD59',
      value: INPUT_VALUE_CLOTH_COLOR_GREEN,
    }),
  ],
  value: INPUT_VALUE_CLOTH_COLOR_BLUE,
  name: INPUT_NAME_CLOTH_COLOR,
});

export const WelcomeToTheWorldInputsLanguage = getBookSelectInputData({
  label: 'Language',
  placeholder: '',
  options: languageArr.map((lang) =>
    getBookSelectInputOptionData({ label: lang, value: lang }),
  ),
  value: INPUT_VALUE_JAPANESE,
  name: INPUT_NAME_LANGUAGE,
} as const);

// * canvas common
const WelcomeToTheWorldInputsCanvasCommon = {
  width: 261,
  height: 444,
  // crop: {
  //   top: 15,
  //   right: 20,
  //   bottom: 18,
  //   left: 23,
  // },
  cropLtMd: {
    // top: 15,
    // left: 76,
    bottom: 135,
  },
  name: INPUT_NAME_RESERVED_CANVAS1,
} as const;

const WelcomeToTheWorldInputsCanvasInputs = [
  WelcomeToTheWorldInputsSkinColor,
  WelcomeToTheWorldInputsFrekles,
  WelcomeToTheWorldInputsHairColor,
  WelcomeToTheWorldInputsHairStyle,
  WelcomeToTheWorldInputsClothColor,
];

const WelcomeToTheWorldInputsCanvasItemsSkin: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S01.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S05.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S02.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S06.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S03.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S07.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S04.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === false,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/S/website-S08.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsSkinColor,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsFrekles,
        condition: (v: boolean) => v === true,
      },
    ],
  },
];

const WelcomeToTheWorldInputsCanvasItemsCloth: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C01.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_BLUE,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C02.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_YELLOW,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C03.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_RED,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/C/website-C04.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsClothColor,
        condition: (v: TClothColor) => v === INPUT_VALUE_CLOTH_COLOR_GREEN,
      },
    ],
  },
];

const WelcomeToTheWorldInputsCanvasItemsHair: IBookCanvasInputItem[] = [
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H01.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLONDE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H05.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLONDE,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H02.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_RED,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H06.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_RED,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H03.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H07.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BROWN,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H04.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_STRAIGHT,
      },
    ],
  },
  {
    imgSrc: 'assets/images/wttw-canvas/H/website-H08.png',
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsHairColor,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLACK,
      },
      {
        inputToCheck: WelcomeToTheWorldInputsHairStyle,
        condition: (v: THairStyle) => v === INPUT_VALUE_HAIR_STYLE_CURL,
      },
    ],
  },
];

export const WelcomeToTheWorldInputsCanvas = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsCanvasCommon,
  label: 'book3.input.canvas.title',
  inputs: WelcomeToTheWorldInputsCanvasInputs,
  items: [
    ...WelcomeToTheWorldInputsCanvasItemsSkin,
    ...WelcomeToTheWorldInputsCanvasItemsCloth,
    ...WelcomeToTheWorldInputsCanvasItemsHair,
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsCanvasInputs),
});

// * non related to initial book-form
export const WelcomeToTheWorldInputsCoverImage = getBookRadioCoverInputData({
  options: [
    getBookRadioCoverInputOptionData({
      label: 'ピクニック',
      value: 'A',
      imgSrc: 'assets/images/wttw-covers/A.png',
    }),
    getBookRadioCoverInputOptionData({
      label: 'ドリーミー',
      value: 'B',
      imgSrc: 'assets/images/wttw-covers/B.png',
    }),
  ],
  // value: 'A',
  name: INPUT_NAME_COVER_IMAGE,
});

// * Season
export const WelcomeToTheWorldInputsSeason = getBookRadioInputData({
  label: '',
  placeholder: '',
  options: [
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
      imgSrc: 'assets/images/season-step/b2_spring.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
    }),
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
      imgSrc: 'assets/images/season-step/b2_summer.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
    }),
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
      imgSrc: 'assets/images/season-step/b2_autumn.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
    }),
    getBookRadioInputOptionData({
      label: INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
      imgSrc: 'assets/images/season-step/b2_winter.png',
      imgExpandTopPx: 21,
      imgExpandRightPx: 35,
      imgExpandBottomPx: 5,
      value: INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
    }),
  ],
  // value: '',
  name: INPUT_NAME_SEASON,
});

// * Parents
const WelcomeToTheWorldInputsParentsCanvasCommon = {
  width: 400,
  height: 348,
  noLtMdBackground: true,
  borderRadius: '10px',
  // crop: {
  //   top: 15,
  //   right: 20,
  //   bottom: 18,
  //   left: 23,
  // },
  // cropLtMd: {
  //   // top: 15,
  //   // left: 76,
  //   bottom: 135,
  // },
  // name: INPUT_NAME_RESERVED_CANVAS1,
} as const;

export const WelcomeToTheWorldInputsP1SkinColor = getBookRadioColorInputData({
  label: 'Skin color',
  options: WelcomeToTheWorldInputsSkinColorOptions,
  value: INPUT_VALUE_SKIN_COLOR_WHITE,
  name: INPUT_NAME_P1_SKIN_COLOR,
});

export const WelcomeToTheWorldInputsP2SkinColor = getBookRadioColorInputData({
  label: 'Skin color',
  options: WelcomeToTheWorldInputsSkinColorOptions,
  value: INPUT_VALUE_SKIN_COLOR_WHITE,
  name: INPUT_NAME_P2_SKIN_COLOR,
});

export const WelcomeToTheWorldInputsP1HairColor = getBookRadioColorInputData({
  label: 'Hair color',
  options: WelcomeToTheWorldInputsHairColorOptions,
  value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  name: INPUT_NAME_P1_HAIR_COLOR,
});

export const WelcomeToTheWorldInputsP2HairColor = getBookRadioColorInputData({
  label: 'Hair color',
  options: WelcomeToTheWorldInputsHairColorOptions,
  value: INPUT_VALUE_HAIR_COLOR_BLONDE,
  name: INPUT_NAME_P2_HAIR_COLOR,
});

const WelcomeToTheWorldInputsParentsCanvas1Inputs = [
  WelcomeToTheWorldInputsP1SkinColor,
  WelcomeToTheWorldInputsP1HairColor,
];

const WelcomeToTheWorldInputsParentsCanvas2Inputs = [
  WelcomeToTheWorldInputsP2SkinColor,
  WelcomeToTheWorldInputsP2HairColor,
];

const getParentsCanvasItemsHair = (
  number: number,
  inputToCheck:
    | typeof WelcomeToTheWorldInputsP1HairColor
    | typeof WelcomeToTheWorldInputsP2HairColor,
): IBookCanvasInputItem[] => [
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-01.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLONDE,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-02.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_RED,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-03.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BROWN,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}H/P${number}H-04.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: THairColor) => v === INPUT_VALUE_HAIR_COLOR_BLACK,
      },
    ],
  },
];

const getParentsCanvasItemsSkin = (
  number: number,
  inputToCheck:
    | typeof WelcomeToTheWorldInputsP1SkinColor
    | typeof WelcomeToTheWorldInputsP2SkinColor,
): IBookCanvasInputItem[] => [
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-01.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_WHITE,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-02.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_NUTMEG,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-03.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BROWN,
      },
    ],
  },
  {
    imgSrc: `assets/images/wttw-canvas/parents/P${number}S/P${number}S-04.png`,
    showWhen: [
      {
        inputToCheck,
        condition: (v: TSkinColor) => v === INPUT_VALUE_SKIN_COLOR_BLACK,
      },
    ],
  },
];

export const WelcomeToTheWorldInputsParentsCanvasP1 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(1, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(1, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP2 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas2Inputs,
  items: [
    ...getParentsCanvasItemsSkin(2, WelcomeToTheWorldInputsP2SkinColor),
    ...getParentsCanvasItemsHair(2, WelcomeToTheWorldInputsP2HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas2Inputs),
  name: INPUT_NAME_RESERVED_CANVAS2,
});

export const WelcomeToTheWorldInputsParentsCanvasP3 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(3, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(3, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP4 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas2Inputs,
  items: [
    ...getParentsCanvasItemsSkin(4, WelcomeToTheWorldInputsP2SkinColor),
    ...getParentsCanvasItemsHair(4, WelcomeToTheWorldInputsP2HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas2Inputs),
  name: INPUT_NAME_RESERVED_CANVAS2,
});

export const WelcomeToTheWorldInputsParentsCanvasP5 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(5, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(5, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP6 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas2Inputs,
  items: [
    ...getParentsCanvasItemsSkin(6, WelcomeToTheWorldInputsP2SkinColor),
    ...getParentsCanvasItemsHair(6, WelcomeToTheWorldInputsP2HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas2Inputs),
  name: INPUT_NAME_RESERVED_CANVAS2,
});

export const WelcomeToTheWorldInputsParentsCanvasP7 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(7, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(7, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasP8 = getBookCanvasInputData({
  ...WelcomeToTheWorldInputsParentsCanvasCommon,
  inputs: WelcomeToTheWorldInputsParentsCanvas1Inputs,
  items: [
    ...getParentsCanvasItemsSkin(8, WelcomeToTheWorldInputsP1SkinColor),
    ...getParentsCanvasItemsHair(8, WelcomeToTheWorldInputsP1HairColor),
  ],
  value: bookInputsToNameValueMap(WelcomeToTheWorldInputsParentsCanvas1Inputs),
  name: INPUT_NAME_RESERVED_CANVAS1,
});

export const WelcomeToTheWorldInputsParentsCanvasArr = [
  WelcomeToTheWorldInputsParentsCanvasP1,
  WelcomeToTheWorldInputsParentsCanvasP2,
  WelcomeToTheWorldInputsParentsCanvasP3,
  WelcomeToTheWorldInputsParentsCanvasP4,
  WelcomeToTheWorldInputsParentsCanvasP5,
  WelcomeToTheWorldInputsParentsCanvasP6,
  WelcomeToTheWorldInputsParentsCanvasP7,
  WelcomeToTheWorldInputsParentsCanvasP8,
];

// * mutations
bookInputValidatorWhen(WelcomeToTheWorldInputsKidsName, [
  {
    validator: WelcomeToTheWorldInputsKidsNameValidatorEn,
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_ENGLISH,
      },
    ],
  },
  {
    validator: WelcomeToTheWorldInputsKidsNameValidator,
    showWhen: [
      {
        inputToCheck: WelcomeToTheWorldInputsLanguage,
        condition: (language: TLanguage) => language === INPUT_VALUE_JAPANESE,
      },
    ],
  },
]);

// WelcomeToTheWorldInputsCanvas
bookInputShowWhen(
  WelcomeToTheWorldInputsCanvas,
  WelcomeToTheWorldInputsKidsName,
  // value is not passed when invalid
  (value?: string) => !!value,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsCanvas,
  WelcomeToTheWorldInputsKidsBirthday,
  // value is not passed when invalid
  (value?: Date) => !!value,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsCanvas,
  WelcomeToTheWorldInputsParents,
  // value is not passed when invalid
  (value?: string) => !!value,
);

// WelcomeToTheWorldInputsParentsCanvas
bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP1,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP2,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP3,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_MOM,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP4,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM_AND_MOM,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP5,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_DAD_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP6,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_DAD_AND_DAD,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP7,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_MOM,
);

bookInputShowWhen(
  WelcomeToTheWorldInputsParentsCanvasP8,
  WelcomeToTheWorldInputsParents,
  (value?: TParents) => value === INPUT_VALUE_PARENTS_DAD,
);

const prices = {
  [INPUT_VALUE_COVER_TYPE_SOFT]: getBookPriceData(
    BOOK_PRICE_WELCOME_TO_THE_WOLD,
  ),
  [INPUT_VALUE_COVER_TYPE_HARD]: getBookPriceData(
    BOOK_PRICE_WELCOME_TO_THE_WOLD_HARD_COVER,
  ),
} as const;

const coverSoft = getCoverSoft({
  price: prices[INPUT_VALUE_COVER_TYPE_SOFT],
  carousel: [
    'assets/images/covers/bk3/bk3-SC01.jpg',
    'assets/images/covers/bk3/bk3-SC02.jpg',
    'assets/images/covers/bk3/bk3-SC03.jpg',
  ],
});
const coverHard = getCoverHard({
  price: prices[INPUT_VALUE_COVER_TYPE_HARD],
  carousel: [
    'assets/images/covers/bk3/bk3-HC01.jpg',
    'assets/images/covers/bk3/bk3-HC02.jpg',
    'assets/images/covers/bk3/bk3-HC03.jpg',
  ],
});

const WelcomeToTheWorld: IBook = {
  caption: 'ようこそ、このせかいへ',
  summary: [
    '出産の記念や、1歳になる子どもへ。あなたが生まれたこの世界には…あなたを愛している人がいる。',
  ],
  alias: BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  prices: [
    [INPUT_VALUE_COVER_TYPE_SOFT, prices[INPUT_VALUE_COVER_TYPE_SOFT]],
    [INPUT_VALUE_COVER_TYPE_HARD, prices[INPUT_VALUE_COVER_TYPE_HARD]],
  ],
  photos: [
    'assets/images/bk3-carousel01-JP.jpg',
    'assets/images/bk3-carousel02-JP.jpg',
    'assets/images/bk3-carousel03-JP.jpg',
    'assets/images/bk3-carousel04-JP.jpg',
  ],
  menuPhoto: 'assets/images/bk3-sample-blue-JP.png',
  previewPhoto: 'assets/images/books03-JP.png',
  properties: {
    purpose: [PURPOSE_ADVENTURE, PURPOSE_BIRTH_GIFT],
    age: {
      start: 0,
      end: 3,
    },
  },
  notice: {
    caption: 'Book new world',
    description: 'welcome.description',
    points: [
      {
        imgSrc: 'assets/images/welcome-notice-point-1.png',
        caption: 'welcome.notice.point.1',
      },
      {
        imgSrc: 'assets/images/welcome-notice-point-2.png',
        caption: 'welcome.notice.point.2',
      },
    ],
  },
  points: [
    {
      caption: 'どんなカスタムができるの？',
      description:
        '・主人公の名前をいれる<br>・表紙の色やリボンの色を選べる<br>・お子さんの成長記録（身長・体重）をいれる<br>・写真をいれる（５枚）<br>・ケーキのロウソクの絵柄を選べる<br> ・メッセージをいれる（最大２か所',
    },
    {
      caption: '絵本のストーリーは？',
      description:
        '主人公が着いたところは、どんなところ？たくさんの自然があり、命がある。そして何より、その子のことが大好きな人がいる。',
    },
    {
      caption: 'ページ数や絵本の大きさは？',
      description:
        '全ての絵本は１冊ずつ、ご注文を受けてからオーダーメイドで印刷・製本されます。マットで高級感のある、環境に配慮した用紙を使用しています。<br><br><b>・全26 ページ<br>・29.7cm x 21cm（本文部分）のA4ヨコ<br>・日本国内で製造</b>',
    },
  ],
  inputs: [
    WelcomeToTheWorldInputsKidsName,
    WelcomeToTheWorldInputsKidsBirthday,
    WelcomeToTheWorldInputsParents,
    WelcomeToTheWorldInputsLanguage,
    WelcomeToTheWorldInputsCanvas,
  ],
  banner: {
    imgSrc: 'assets/images/bk3-banner-desktop-JP.jpg',
    imgSrcLtMd: 'assets/images/bk3-banner-mobile-JP.jpg',
    text: 'まいにちが　だいぼうけん。　ここにある　まほうを　みつけよう。',
    textColor: EBookBannerTextColor.dark,
  },
  description: [
    {
      imgSrc: 'assets/images/bk3-image01-JP.gif',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: 'いろんな家族の形があるから',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '主人公のことをここで待っていたのは、ママとパパ？それともパパとパパ？',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'どんな形の家族でも、その子を大好きな気持ちは同じ。',
        },
      ],
    },
    {
      imgSrc: 'assets/images/bk3-image02-JP.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: '生まれた時の思い出を、絵本の１ページに',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '絵本の最後のページには、主人公の写真を追加することができます。',
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'お名前やお誕生日と一緒に、あの時の思い出を絵本に、ずっと。',
        },
      ],
    },
  ],
  review: {
    text: [
      '「物語のクオリティも高く、家族の思い出としても残しておける品だと思いました。」',
    ],
  },
  reviews: [],
  covers: {
    [INPUT_VALUE_COVER_TYPE_HARD]: coverHard,
    [INPUT_VALUE_COVER_TYPE_SOFT]: coverSoft,
  },
  cover: INPUT_VALUE_COVER_TYPE_SOFT,
};

export default WelcomeToTheWorld;
