import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { IGiftUseCase } from './interfaces';

@Component({
  selector: 'app-section-gift-use-cases',
  templateUrl: './section-gift-use-cases.component.html',
  styleUrls: ['./section-gift-use-cases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftUseCasesComponent {
  public readonly cases: IGiftUseCase[] = [
    {
      caption: /* html */ `お会計だけ<wbr>済ませられる`,
      photo: 'assets/images/gift-case-1.png',
      summary: [
        'お仕立て券のように、先にお支払いだけ済ませておいて、贈る相手に絵本を作ってもらうことができます。',
      ],
    },
    {
      caption: /* html */ `子供の名前がわからな<wbr>くても大丈夫！`,
      photo: 'assets/images/gift-case-2.png',
      summary: [
        '絵本を作るのは贈る相手。だから子供の名前や誕生日がわからなくても、絵本を贈れます。',
      ],
    },
    {
      caption: /* html */ `SNSでも気軽に<wbr>プレゼント`,
      photo: 'assets/images/gift-case-3.png',
      summary: [
        'SNS で仲良しのあの人へ。贈り先の名前や住所がわからなくても、絵本を贈ることができます。',
      ],
    },
  ];

  // constructor() {}
}
