<div class="wrap">
  <div class="wrap__bg">
    <app-background></app-background>
  </div>
  <div class="wrap__container">
    <div class="container">
      <div class="container__info">
        <app-info></app-info>
      </div>
    </div>
  </div>
</div>
