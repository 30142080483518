<div
  class="container"
  [ngClass]="{
    container_inverted: inverted
  }"
>
  <div
    #eyeLeft
    class="container__eye container__eye_left"
  >
    <div
      class="container__eyeball"
      #eyeBallLeft
      [ngStyle]="{
        marginLeft: marginLeft | async,
        marginTop: marginTop | async
      }"
    ></div>
  </div>
  <div
    #eyeRight
    class="container__eye container__eye_right"
  >
    <div
      class="container__eyeball"
      #eyeBallRight
      [ngStyle]="{
        marginLeft: marginLeft | async,
        marginTop: marginTop | async
      }"
    ></div>
  </div>
  <svg-icon
    class="container__image"
    src="assets/images/logo_no-eyes.svg"
    [applyClass]="true"
  ></svg-icon>
</div>
