import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SectionAboutBannerComponent } from './section-about-banner.component';

@NgModule({
  declarations: [SectionAboutBannerComponent],
  imports: [CommonModule, RouterModule],
  exports: [SectionAboutBannerComponent],
})
export class SectionAboutBannerModule {}
