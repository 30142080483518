import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { firstValueFrom, Subject } from 'rxjs';
import { Logger } from '@shared/common/logger';
import { slideY } from '@studiobuki/web-core';
import type { IDiscountCampaign } from '@shared/jp/discount/interfaces';
import { getBooksDiscount } from '@shared/jp/discount/utils';
import type { IBookInOrder } from '@shared/jp/interfaces';
import type { TCouponData } from '@shared/common/interfaces';
import type { ICalculatorComponentItem } from '@studiobuki/web-core/lib/calculator';
import { ECalculatorComponentType } from '@studiobuki/web-core/lib/calculator';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { UserService } from '@studiobuki/web-core/lib/user';
import { RoutingService } from 'src/app/services/routing.service';
import { wrappings } from 'src/static';
import type { IProduct } from 'src/types';
import {
  getProductPrice,
  getProduct,
  getCover,
  getBookDataSpecs,
  getCalculatorItems,
} from 'src/utils';
import Subscriber from '@shared/common/subscriber';
import type { IShipping } from '@shared/jp/data/shipping/types';
import { getKidstitle } from '@shared/jp/book/utils';
import type { TBookAlias } from '@shared/jp/book/interfaces';
import type { TBookData } from '@shared/jp/models';

const log = new Logger('AsideComponent');

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  animations: [slideY()],
})
export class AsideComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title = 'ご注文内容';

  @Input() coupon?: TCouponData | undefined;

  @Input() books!: IBookInOrder[];

  /** selected shipping */
  @Input() shipping?: IShipping | undefined; // * index of shipping type

  @Input() alwaysExpanded = false;

  @Input() hidePrice = false;

  @Output() editCartClick = new EventEmitter<void>();

  public products: IProduct[] = []; // getting on {books} update

  public calculatorItems: ICalculatorComponentItem[] = [];

  private booksUpdate = new Subject<void>();

  private couponUpdate = new Subject<void>();

  private shippingUpdate = new Subject<void>();

  public CalculatorComponentType = ECalculatorComponentType;

  public readonly getBooksDiscount = getBooksDiscount;

  private readonly _sub = new Subscriber();

  private _isExpanded = false;

  get isExpanded(): boolean {
    return (
      this.alwaysExpanded || this.media.isActive('gt-lg') || this._isExpanded
    );
  }

  set isExpanded(expanded: boolean) {
    this._isExpanded = expanded;
  }

  get isHeaderToggleVisible() {
    return !this.alwaysExpanded && this.media.isActive('lt-xl');
  }

  get isHeaderLinkVisible() {
    return !this.media.isActive('lt-xl');
  }

  get isHeaderCalculatorVisible() {
    return this.calculatorItems && this.media.isActive('lt-xl');
  }

  get isHeaderContentVisible() {
    return (
      this.isHeaderToggleVisible ||
      this.isHeaderLinkVisible ||
      this.isHeaderCalculatorVisible
    );
  }

  constructor(
    private userService: UserService<TBookAlias, TBookData>,
    private media: MediaObserver,
    public discountService: DiscountService,
    private _routing: RoutingService,
  ) {}

  public getProductPrice = (product: IProduct) =>
    this.hidePrice ? undefined : getProductPrice(product);

  async ngOnInit() {
    await this.updateProducts();
    this.updateCalculatorItems();

    this._sub.push(
      this.booksUpdate.subscribe(async () => {
        await this.updateProducts();
        this.updateCalculatorItems();
      }),

      this.shippingUpdate.subscribe(() => {
        this.updateCalculatorItems();
      }),

      // handle couponUpdate to update calculation
      this.couponUpdate.subscribe(() => {
        this.updateCalculatorItems();
      }),

      this.discountService.activeDiscountCampaign$.subscribe(
        (discountCampaign) => {
          this.updateCalculatorItems(undefined, discountCampaign);
        },
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const { books, shipping, coupon } = changes;

      if (books?.currentValue) {
        this.booksUpdate.next();
      }

      if (shipping?.currentValue) {
        this.shippingUpdate.next();
      }

      if (coupon?.currentValue) {
        this.couponUpdate.next();
      }
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private async updateProducts() {
    const { books } = this;

    if (!books) {
      log.warn('updateProducts: !books');
      return;
    }

    const booksList = await firstValueFrom(this.userService.booksList$);

    this.products.length = 0;

    books.forEach((bookInOrder) => {
      const book = booksList.find((b) => b.bookId === bookInOrder.bookId);

      if (!book) {
        log.error(
          'updateProducts: book',
          bookInOrder,
          'not found by id in',
          booksList,
        );
        return;
      }

      this.products.push(
        getProduct({
          alias: bookInOrder.alias,
          bookId: bookInOrder.bookId,
          hero:
            book.heroName +
            ('kunChan' in book ? getKidstitle(book.kunChan) : ''),
          gender: 'gender' in book ? book.gender : undefined,
          children:
            'grandchildren' in book
              ? book.grandchildren.data.map(({ childrenName }) => childrenName)
              : undefined,
          wrapping: wrappings.find((w) => w.id === bookInOrder.wrapping),
          cover: getCover(bookInOrder.alias, bookInOrder.cover),
          specs: getBookDataSpecs(book),
        }),
      );

      // console.log('pushed', book, this.products[this.products.length - 1]);
    });
  }

  public async updateCalculatorItems(
    products = this.products,
    discountCampaign?: IDiscountCampaign,
    couponData = this.coupon,
  ) {
    if (this.hidePrice || !this.shipping) return;

    discountCampaign =
      discountCampaign ||
      (await firstValueFrom(this.discountService.activeDiscountCampaign$));

    this.calculatorItems = getCalculatorItems(
      products,
      discountCampaign,
      couponData,
      this.shipping,
    );
  }

  public toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  public onEditCartClick() {
    if (this.editCartClick.observers.length) {
      this.editCartClick.emit();
    } else {
      this._routing.goToCart();
    }
  }
}
