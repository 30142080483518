import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { scrollToSelector } from 'src/utils';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  constructor(public mediaService: MediaService) {}

  public onButtonClick(): void {
    scrollToSelector('#preform');
  }
}
