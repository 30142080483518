<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
    *ngIf="media.isLtXl$ | async"
  ></app-section-header-gap>
  <app-section-header-banner
    class="wrap__section-header-banner"
  ></app-section-header-banner>
  <div class="wrap__section-description">
    <div class="description">
      <p class="description__passage">
        私たちは、日々みなさんの日常に関わる”電車”や”絵本”を通して、子どもたちの夢や大好きを応援し続けたいと思っています。
      </p>
      <p class="description__passage">
        憧れの運転士さんになれる物語。大好きな電車にまつわる物語。
      </p>
      <p class="description__passage">
        いろんな物語を通して「ぼくは/私はなんだって叶えられる！」という、どんなまっくらな中でも、キラキラかがやく“希望”を育んでいきたい。<br />電車は「駅にいる全ての人を目的地に運ぶことが使命」の乗り物です。
      </p>
      <p class="description__highlighted-passage">
        私たちは責任をもって子どもたちを、目指す場所へ、大切な夢へ、ワクワクする未来へ、連れていきます。
      </p>
    </div>
  </div>
  <div class="wrap__section-photo">
    <picture class="photo">
      <source
        media="(max-width: 767px)"
        srcset="assets/images/team_mobile.jpg"
      />
      <img
        src="assets/images/team.jpg"
        alt="Banner background image."
      />
    </picture>
  </div>
  <div class="wrap__section-message">
    <app-section-message></app-section-message>
  </div>
  <div class="wrap__section-collage">
    <picture class="collage">
      <source
        media="(max-width: 767px)"
        srcset="assets/images/collage_mobile.jpg"
      />
      <img
        src="assets/images/collage.jpg"
        alt="Banner background image."
      />
    </picture>
  </div>
  <div class="wrap__section-story">
    <app-section-story></app-section-story>
  </div>
  <div class="wrap__section-business-card">
    <app-section-business-card></app-section-business-card>
  </div>
</div>
