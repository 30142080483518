<div class="spec">
  <span
    class="spec__key"
    *ngIf="key"
    translate
    >{{ key }}</span
  >
  <span
    class="spec__value"
    *ngIf="value"
    >{{ value }}</span
  >
</div>
