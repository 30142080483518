<div class="wrap">
  <div class="wrap__image"></div>
  <div class="wrap__container">
    <div class="container">
      <div class="container__content">
        <div class="content">
          <div class="content__title">
            <lib-image-title type="story">絵本作りの背景</lib-image-title>
          </div>
          <div class="content__text">
            東京メトロが作る、電車絵本。きっと読んでくれるのは、電車が好きな子たちが多いでしょう。<br />
            それなら、よーく電車のことを知っている子たちに満足してもらえる絵本にしないと！という使命感のもと、絵本作りがスタート。<br /><br />

            そうそう！1000系ってこうだよね、丸ノ内線ってそうなってるんだ！と、子鉄くん・子鉄ちゃんたちも知らなかったような、驚くほど細かい部分もできるだけリアルに近づけました。車両だけじゃありません。運転士の制服やカバンなど、細かいところも再現しました。<br /><br />

            東京メトロの車両部や運転部のお墨付きです。<br />
            今度ぜひ、東京メトロの電車に乗った時には、絵本の中と見比べてみてください。<br /><br /><br />

            <a
              href="https://hellobuki.com/jp/blog/recommended/tokyometro_buki_bookproductionbhs/"
              target="_blank"
              >制作の裏話を読む</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
