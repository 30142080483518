import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { TBookCover } from '@shared/jp/book/interfaces';
import type { IBook } from '@shared/jp/data/books/types';
import type { TWithDocId } from '@shared/common/interfaces';
import type { ITaskPrintBookData } from '@shared/jp/interfaces';
import type { TBookData } from '@shared/jp/models';
import Subscriber from '@shared/common/subscriber';
import { getError } from '@shared/common/utils';
import type { Values } from '@studiobuki/web-core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import {
  ROUTE_PATH_QUERY_PARAM_MODE,
  ROUTE_PATH_QUERY_PARAM_MODE_PRINT,
} from '@studiobuki/web-core/lib/routing';
import { combineLatest } from 'rxjs';
import TokyoMetro from 'src/app/data/books/TokyoMetro';
import { RoutingService } from 'src/app/services/routing.service';

// const log = new Logger('CoverPageComponent');

@Component({
  templateUrl: './cover-page.component.html',
  styleUrls: ['./cover-page.component.scss'],
})
export class CoverPageComponent implements OnInit, OnDestroy {
  private _sub = new Subscriber();

  private _isLoaded = false;

  set isLoaded(status: boolean) {
    if (status) {
      this._loaderService.hide();
    } else {
      this._loaderService.show();
    }

    this._isLoaded = status;
  }

  get isLoaded(): boolean {
    return this._isLoaded;
  }

  private _bookData?: TBookData | undefined;

  private _taskPrintBookData?: TWithDocId<ITaskPrintBookData> | undefined;

  public covers?: IBook['covers'];

  public coversArr?: Values<IBook['covers']>[];

  public cover?: IBook['cover'];

  constructor(
    private _route: ActivatedRoute,
    private _loaderService: LoaderService,
    private _firebaseService: FirebaseService,
    private _routing: RoutingService,
  ) {
    this.isLoaded = false;
  }

  ngOnInit(): void {
    const { paramMap, queryParamMap } = this._route;

    this._sub.push(
      combineLatest([paramMap, queryParamMap]).subscribe(
        async ([_paramMap, _queryParamMap]) => {
          const bookId = _paramMap.get('id');
          const mode = _queryParamMap.get(ROUTE_PATH_QUERY_PARAM_MODE);

          if (!bookId) throw getError('!bookId');

          if (mode === ROUTE_PATH_QUERY_PARAM_MODE_PRINT) {
            const taskPrintBookData =
              await this._firebaseService.getPrintBook<TBookData>(bookId);

            this._taskPrintBookData = taskPrintBookData;
            this._bookData = taskPrintBookData?.bookData;
          } else {
            this._bookData = await this._firebaseService.getUserBook(bookId);
          }

          if (!this._bookData) throw getError('!bookData');

          // TODO: ?
          // const book = getBookByAlias(this._bookData.alias);
          const book = TokyoMetro;

          this.covers = book.covers;
          this.coversArr = Object.values(book.covers);
          this.cover = this._bookData.cover;

          this.isLoaded = true;
        },
      ),
    );
  }

  public async selectCover(coverToSet: TBookCover) {
    const { _bookData, _taskPrintBookData, covers, cover } = this;

    if (!_bookData || !covers || !cover)
      throw getError('missing required data', { _bookData, covers, cover });

    if (coverToSet === cover) throw getError('covers are equal');

    this.cover = coverToSet;

    try {
      if (_taskPrintBookData) {
        await this._firebaseService.setPrintBook(
          _taskPrintBookData.docId,
          {
            bookData: Object.assign(_bookData, { cover: coverToSet }),
          },
          { merge: true },
        );
      } else {
        await this._firebaseService.setUserBook(
          _bookData.bookId,
          { cover: coverToSet },
          { merge: true },
        );
      }
    } catch (error) {
      this.cover = cover;
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public onBackClick() {
    const { _bookData, _taskPrintBookData } = this;

    if (!_bookData) throw getError('!_bookData');

    if (_taskPrintBookData) {
      return this._routing.goToPrintBook(_taskPrintBookData.docId);
    }

    return this._routing.goToBook(_bookData.bookId);
  }

  public onNextClick() {
    return this._routing.goToCart();
  }
}
