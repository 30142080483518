import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewModule } from '@studiobuki/web-core/lib/preview';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionFooterBannersModule } from 'src/app/shared/section-footer-banners/section-footer-banners.module';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { AboutPageComponent } from './about-page.component';
import { SectionHeaderBannerComponent } from './components/section-header-banner/section-header-banner.component';
import { SectionMessageComponent } from './components/section-message/section-message.component';
import { SectionStoryComponent } from './components/section-story/section-story.component';
import { SectionBusinessCardComponent } from './components/section-business-card/section-business-card.component';

@NgModule({
  declarations: [
    AboutPageComponent,
    SectionHeaderBannerComponent,
    SectionMessageComponent,
    SectionStoryComponent,
    SectionBusinessCardComponent,
  ],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    PreviewModule,
    SectionFooterBannersModule,
    ImageTitleModule,
  ],
})
export class AboutPageModule {}
