<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title type="gift">ギフトコードで絵本を贈る</lib-image-title>
      </div>
      <div class="container__content">
        <p class="text">
          お仕立券のように、もっと気軽に贈れるギフトコードでのプレゼントはいかがでしょうか。
          <br /><br />
        </p>
        <p class="text">
          ※ギフトコードはメールで送る英数字のみとなり、印刷されたカード等では届きません。<br />
          ギフトコードを受け取った方が、ギフトコードを利用した際に、絵本がその方宛に届きます。
        </p>
      </div>
    </div>
  </div>
</div>
