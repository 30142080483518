import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import type { TFaqData } from '@shared/common/data/faq/types';
import {
  ROUTE_PATH_ALL_BOOKS,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_INDEX,
} from '@studiobuki/web-core/lib/routing';
import { BASE_HREF } from 'src/app/app.contants';

const indexHref = `${BASE_HREF}${ROUTE_PATH_INDEX}#preform`;
const contactsHref = `${BASE_HREF}${ROUTE_PATH_CONTACTS}`;
const allBooksHref = `${BASE_HREF}${ROUTE_PATH_ALL_BOOKS}`;

const link = (href: string, content: string) =>
  /* html */ `<a href="${href}" target="_blank">${content}</a>`;

@Component({
  selector: 'app-section-gift-faq',
  templateUrl: './section-gift-faq.component.html',
  styleUrls: ['./section-gift-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftFaqComponent {
  public readonly faqData: TFaqData = [
    {
      question: '購入したギフトコードはどうやったらわかりますか？',
      answer: /* html */ `
      お支払い完了後に送信されるご注文完了メールに（クレジットカードでお支払いの場合は、お支払い完了後の画面にも）ギフトコードを記載しています。
      <br>
      <br>
      メールが届いていない場合は、恐れ入りますが${link(
        contactsHref,
        'お問い合わせページ',
      )}に記載のLINE窓口もしくはお電話でご連絡ください。
    `,
    },
    {
      question: 'ギフトコードを相手に渡すにはどうしたらいいですか？',
      answer: /* html */ `
      お客さまご自身で、ご注文後にメールで送られたギフトコードやリンク先を、相手の方にメールやLINE、SNS等でお知らせください。
      <br>
      <br>
      ギフトコードが印刷されたカードがお手元に届くことはありませんので、ご注意ください。
    `,
    },
    {
      question: 'ギフトコードの購入前に絵本の内容を確認できますか？',
      answer: /* html */ `${link(
        indexHref,
        'こちら',
      )}から仮のお子様のお名前等を入れれば、絵本の試し読みができます。`,
    },
    {
      question: 'ギフトコード利用の場合、絵本の発送・送料はどうなりますか？',
      answer: /* html */ `ギフトコードには、全て送料込みのお値段でご案内しております。なお、発送方法はポスト投函・日時指定なし・追跡ありのメール便（クロネコゆうパケット）のみとなります。<br><br>ギフトコードを受け取った方がお手続きを終了後、最短で（概ね１週間以内）発送しております。`,
    },
    {
      question: '購入したギフトコードはキャンセルできますか？',
      answer: /* html */ `申し訳ありません、ご購入後のキャンセル・返金には対応できません。`,
    },

    {
      question: 'ギフトコードに有効期限はありますか？',
      answer: /* html */ `ご購入後の有効期限は１年間です。<br>未使用のまま期限を過ぎた場合でも、${link(
        contactsHref,
        'お問い合わせ',
      )}いただければ新しいギフトコードを発行いたします。`,
    },
    {
      question: '複数のギフトコードを購入したいです。',
      answer: /* html */ `申し訳ございません、現在は１回のご注文で１冊分のギフトコードをご購入いただけます。複数のギフトコードをお求めの場合は、恐れ入りますがご注文を分けてお手続きお願いします。`,
    },
  ];

  constructor(private _router: Router) {}

  onButtonClick() {
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }
}
